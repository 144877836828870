@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body, html{
  background-color:#ECECEC;
  padding-top:10px;
}

.logo{
  width: 300px;
  text-align: center;
  margin: 24px auto;
}
